"use client";

import React, { useState } from "react";
import { Card } from "@/edges/ui/Card";
import { InteractiveMap, InteractiveMapProps } from "@/edges/ui/InteractiveMap";
import { Button } from "@/edges/ui/Button";
import { Site } from "@/@codegen/supergraph";
import Link from "next/link";
interface HomePageMapProps extends Omit<InteractiveMapProps, "data"> {
  title: string;
  sites: Site[];
}
export const HomePageMap: React.FC<HomePageMapProps> = ({
  title,
  sites,
  ...mapProps
}) => {
  const locations = sites ? sites.map(site => ({
    id: site.id,
    latitude: site.location.latitude,
    longitude: site.location.longitude
  })) : [];
  const [selectedSite, setSelectedSite] = useState<Site | null>(null);
  const DrawerContent = ({
    site
  }: {
    site: Site;
  }) => {
    return <div className="flex h-full flex-col justify-between" data-sentry-component="DrawerContent" data-sentry-source-file="HomePageMap.tsx">
        <div className="flex flex-col gap-1 py-4">
          <div className="text-lg font-semibold">{site.location.streetOne}</div>
          {site.location.streetTwo && <div>{site.location.streetTwo}</div>}
          <div>
            {site.location.city}, {site.location.state}{" "}
            {site.location.postalCode}
          </div>
        </div>
        <Link href={`/sites/${site.id}`} data-sentry-element="Link" data-sentry-source-file="HomePageMap.tsx">
          <Button variant="secondary" className="w-full" data-sentry-element="Button" data-sentry-source-file="HomePageMap.tsx">
            View site details
          </Button>
        </Link>
      </div>;
  };
  return <Card heading={title} data-sentry-element="Card" data-sentry-component="HomePageMap" data-sentry-source-file="HomePageMap.tsx">
      <div style={{
      height: "400px",
      width: "100%"
    }}>
        <InteractiveMap {...mapProps} data={locations} onPointClick={pointId => {
        const site = sites.find(site => site.id === pointId);
        if (site) {
          setSelectedSite(site);
        }
      }} scrollEnabled={false} drawerComponent={selectedSite && <DrawerContent site={selectedSite} />} className="border" data-sentry-element="InteractiveMap" data-sentry-source-file="HomePageMap.tsx" />
      </div>
    </Card>;
};