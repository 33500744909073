import React from "react";
import { chartClassNames } from "@/edges/types/ChartProps";
export const AreaLoading: React.FC = () => {
  return <div className={chartClassNames.container} data-sentry-component="AreaLoading" data-sentry-source-file="AreaLoading.tsx">
      <div className="flex h-full w-full flex-col space-y-4 rounded-lg p-4">
        {/* Y-axis ticks */}
        <div className="absolute left-0 flex h-full flex-col justify-between py-6">
          <div className="h-4 w-8 animate-pulse rounded bg-gray-light" />
          <div className="h-4 w-8 animate-pulse rounded bg-gray-light" />
          <div className="h-4 w-8 animate-pulse rounded bg-gray-light" />
          <div className="h-4 w-8 opacity-0" />
        </div>

        {/* Main chart area */}
        <div className="relative h-full w-full">
          {/* Background grid lines */}
          <div className="absolute inset-0 flex flex-col justify-between py-6">
            <div className="h-px w-full bg-border-muted" />
            <div className="h-px w-full bg-border-muted" />
            <div className="h-px w-full bg-border-muted" />
            <div className="h-px w-full bg-border-muted" />
          </div>

          {/* Loading gradient */}
          <div className="absolute inset-x-12 top-1/4 h-32 animate-pulse rounded-lg bg-gradient-to-b from-gray-light to-gray-lighter" />
        </div>

        {/* X-axis labels */}
        <div className="flex justify-between px-12">
          <div className="h-4 w-16 animate-pulse rounded bg-gray-light" />
          <div className="h-4 w-16 animate-pulse rounded bg-gray-light" />
          <div className="h-4 w-16 animate-pulse rounded bg-gray-light" />
          <div className="h-4 w-16 animate-pulse rounded bg-gray-light" />
        </div>
      </div>

      {/* Bottom bar - just a plain background */}
      <div className="mt-4 h-12 w-full rounded-lg bg-background-muted" />
    </div>;
};