"use client";

import React, { useState } from "react";
import { gql, useQuery } from "@apollo/client";
import { useFeatureIsOn } from "@growthbook/growthbook-react";
import { Key } from "react-aria-components";
import { Button } from "@/edges/ui/Button";
import { ChartCard } from "@/edges/ui/ChartCard";
import { MetricCard } from "@/edges/ui/MetricCard";
import { AreaChart } from "@/edges/ui/AreaChart";
import { prepareChartData } from "@/components/utils";
import Tabs from "@/components/Tabs/Tabs";
import { useTranslations } from "next-intl";
import { Text } from "@/components/Typography";
import { Gap } from "@/components/Layout";
import { EmptyState } from "@/components/EmptyStates";
import { useNavigationService } from "@/services/navigationService";
import EnergyStorageTab from "./components/EnergyStorageTab";
import EnergyConsumptionTab from "./components/EnergyConsumptionTab";
import EnergyProductionTab from "./components/EnergyProductionTab";
import EnergyCapacityTab from "./components/EnergyCapacityTab";
import GridEnergyTab from "./components/GridEnergy";
import { useSelectedWorkspace } from "@/state/user/useSelectedWorkspace";
import { DateRanges } from "@/utils/date";
import HomepageEmptyState from "./components/HomepageEmptyState";
import { HomePageMap } from "./components/HomePageMap";
import { Site } from "@/@codegen/supergraph";
import { HomePageResponse, calculateCounts, calculateGrowth, formatDataForChart } from "./utils";
import { ActivityFeed } from "@/components/ActivityFeed";

// @TODO: Remove events query from this
const GET_DATA_FOR_HOME_PAGE = gql`
  query GetDataForHomePage(
    $workspaceId: ID!
    $devicesInput: DevicesInput!
    $sitesInput: SitesInput!
  ) {
    events: events(
      workspaceId: $workspaceId
      input: {
        page: 1
        perPage: 10
        eventTypes: [
          COMMAND_FAILED
          COMMAND_SUCCEEDED
          DEVICE_DISCONNECTED
          DEVICE_DISCOVERED
        ]
      }
    ) {
      id
      eventType
      timestamp
      payload
    }
    devices: devices(input: $devicesInput) {
      data {
        id
        createdAt
        type
      }
      meta {
        totalCount
      }
    }
    sites: sites(input: $sitesInput) {
      data {
        id
        createdAt
        location {
          streetOne
          streetTwo
          city
          state
          postalCode
          country
          latitude
          longitude
        }
      }
    }
  }
`;
export default function HomePage(): JSX.Element {
  const t = useTranslations("HomePage");
  const {
    selectedWorkspace
  } = useSelectedWorkspace();
  const navigationService = useNavigationService();
  const homePageNewEnergyMetrics = useFeatureIsOn("home-page-new-energy-metrics");
  const [selectedDataset, setSelectedDataset] = useState<Key>("Count of sites");
  const [selectedDateRange, setSelectedDateRange] = useState(DateRanges.Last7Days);
  const {
    data,
    loading,
    error
  } = useQuery<HomePageResponse>(GET_DATA_FOR_HOME_PAGE, {
    variables: {
      workspaceId: selectedWorkspace?.id,
      devicesInput: {
        filter: {
          workspaceId: selectedWorkspace?.id
        },
        pagination: {
          offset: {
            perPage: 1000,
            page: 1
          }
        }
      },
      sitesInput: {
        filter: {
          workspaceId: selectedWorkspace?.id
        },
        pagination: {
          offset: {
            perPage: 1000,
            page: 1
          }
        }
      }
    },
    skip: !selectedWorkspace?.id // Skip query if workspace ID is not available
  });
  if (loading || !data) return <></>;
  if (error) return <div>Error: {error.message}</div>;

  // Calculate counts and trends for sites and devices
  const sitesCounts = calculateCounts(data, "sites");
  const devicesCounts = calculateCounts(data, "devices");
  const sitesGrowth = calculateGrowth(sitesCounts.countThisWeek, sitesCounts.totalCount);
  const devicesGrowth = calculateGrowth(devicesCounts.countThisWeek, devicesCounts.totalCount);
  const deviceCountDataset = {
    title: "Count of devices",
    data: formatDataForChart(data, "devices"),
    xKey: "time",
    yKey: "value"
  };
  const siteCountDataset = {
    title: "Count of sites",
    data: formatDataForChart(data, "sites"),
    xKey: "time",
    yKey: "value"
  };
  const networkDatasets = [siteCountDataset, deviceCountDataset];
  if (devicesCounts.totalCount === 0 && sitesCounts.totalCount === 0) {
    return <HomepageEmptyState />;
  }
  return <div className="flex w-full max-w-content flex-col gap-2" data-sentry-component="HomePage" data-sentry-source-file="page.tsx">
      <div className="grid grid-cols-1 gap-1 md:grid-cols-2">
        {[{
        title: "Sites",
        counts: sitesCounts,
        growth: sitesGrowth
      }, {
        title: "Devices",
        counts: devicesCounts,
        growth: devicesGrowth
      }].map(({
        title,
        counts,
        growth
      }) => <MetricCard key={title} title={title} value={counts.totalCount} trend={{
        value: parseFloat(growth.toFixed(1)),
        label: "added this week"
      }} />)}
      </div>
      {data.sites.data.length > 0 && <HomePageMap title="Site Locations" sites={data.sites.data as Site[]} />}
      <div className="flex gap-1">
        <ChartCard title="Network growth" selectedDataset={selectedDataset} onDatasetChange={(value: Key) => setSelectedDataset(value)} datasetOptions={networkDatasets.map(dataset => ({
        label: dataset.title,
        value: dataset.title
      }))}
      // selectedRange={selectedDateRange}
      // onRangeChange={(value) => setSelectedDateRange(value)}
      showWindowSize={false} showRangeSelector={false} data-sentry-element="ChartCard" data-sentry-source-file="page.tsx">
          <AreaChart data={prepareChartData({
          data: networkDatasets.find(dataset => dataset.title === selectedDataset)?.data ?? [],
          xKey: networkDatasets.find(dataset => dataset.title === selectedDataset)?.xKey ?? "",
          yKey: networkDatasets.find(dataset => dataset.title === selectedDataset)?.yKey ?? ""
        })} xLabel="Time" yLabel="Value" yFormatType="number" data-sentry-element="AreaChart" data-sentry-source-file="page.tsx" />
        </ChartCard>
      </div>
      <div className="flex gap-1">
        <ActivityFeed events={data.events} data-sentry-element="ActivityFeed" data-sentry-source-file="page.tsx" />
      </div>
      <Gap rem={1} data-sentry-element="Gap" data-sentry-source-file="page.tsx" />
      {homePageNewEnergyMetrics ? <Tabs>
          <Tabs.Tab name={t("energyConsumption")}>
            <EnergyConsumptionTab />
          </Tabs.Tab>
          <Tabs.Tab name={t("energyProduction")}>
            <EnergyProductionTab />
          </Tabs.Tab>
          <Tabs.Tab name={t("energyStorage")}>
            <EnergyStorageTab />
          </Tabs.Tab>
          <Tabs.Tab name={t("energyCapacity")}>
            <EnergyCapacityTab />
          </Tabs.Tab>
          <Tabs.Tab name={t("gridEnergy")}>
            <GridEnergyTab />
          </Tabs.Tab>
        </Tabs> : <Tabs>
          <Tabs.Tab name={t("energy")}>
            <div className="relative">
              <>
                <EmptyState className="absolute top-20">
                  <span className="text-sm text-text-body">
                    {t("energyMetricsComingSoon")}
                  </span>
                  <Button onPress={navigationService.navigateToSources} variant="secondary">
                    {t("goToSources")}
                  </Button>
                </EmptyState>
              </>
            </div>
          </Tabs.Tab>
        </Tabs>}
    </div>;
}